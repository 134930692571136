import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import {
  saveContactForm,
  saveFinalDetails
} from '../../services/generic.service';
import SimpleModal from '../../components/SimpleModal/SimpleModal';
import Loader from '../../components/Loader/Loader';
import './PersonalContact.scss';

const pathToServe = process.env.SERVE_PATH;

const PersonalContact = () => {
  const location = useLocation();
  const roomQnt = localStorage.getItem('roomQuantity');
  const roomSg = JSON.parse(localStorage.getItem('roomSuggestions'))
    ? JSON.parse(localStorage.getItem('roomSuggestions'))
    : [];
  const roomTypes = JSON.parse(localStorage.getItem('roomTypes'))
    ? JSON.parse(localStorage.getItem('roomTypes'))
    : [];
  const navigate = useNavigate();
  const [form, setForm] = useState({
    input_1: '',
    input_2: '',
    input_3: '',
    input_4: '',
    input_5: '',
    input_9: '',
    input_7: '',
    input_8_1: '',
    input_10: '> 4 Rooms'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formContent, setFormContent] = useState(null);

  function calculateW(width, height, depth) {
    let total = Math.floor(width * height * depth);
    total *= 25;
    return total;
  }

  const onPhoneNumberChanged = e => {
    const num = e.target.value;
    let newText = '';
    const numbers = '+0123456789';

    for (let i = 0; i < num.length; i++) {
      if (numbers.indexOf(num[i]) > -1) {
        // eslint-disable-next-line operator-assignment
        newText = newText + num[i];
      } else {
        // your call back function
        console.log('error');
      }
    }
    setForm({ ...form, input_7: newText });
  };

  useEffect(() => {
    if (location.state) {
      let roomInfos = '';
      roomTypes.forEach((r, index) => {
        roomInfos += `\n \n Raumtyp${index + 1}: Allgemein, \n Grösse: '${r.depth
          } x ${r.width} x ${r.height}' \n Leistungsbedarf${index +
          1}: ${calculateW(r.width, r.height, r.depth)}`;
      });
      roomInfos = roomInfos.slice(0, -4);
      setForm({
        ...form,
        input_9: `Anzahl Räume: ${roomQnt} \n Paket: ${roomSg.length !== 0 ? roomSg[0].name : ''
          }${roomInfos}`,
        input_10: '',
        input_11: ''
      });
    }
  }, []);

  const onSubmit = async () => {
    // console.log('roomTypes', roomTypes);
    setIsLoading(true);
    if (!location.state) {
      await saveContactForm(form).then((response) => {
        setFormContent(response);
        setShowModal(true);

        // Clear form
        setForm({
          input_1: '',
          input_2: '',
          input_3: '',
          input_4: '',
          input_5: '',
          input_9: '',
          input_7: '',
          input_8_1: '',
          input_10: '> 4 Rooms'
        });
      });
      setIsLoading(false);

      return;
    }
    await saveFinalDetails(form).then((response) => {
      setFormContent(response);
      setShowModal(true);

      // Clear form
      setForm({
        input_1: '',
        input_2: '',
        input_3: '',
        input_4: '',
        input_5: '',
        input_9: '',
        input_7: '',
        input_8_1: '',
        input_10: '> 4 Rooms'
      });
    });
    setIsLoading(false);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 left-section">
          <div className="col h-500 left-panel title-panel">
            <svg
              id="Capa_1"
              enableBackground="new 0 0 512 512"
              fill="#F5F5F5"
              color="red"
              viewBox="0 0 512 512"
              // width="100"
              // height="100"
              xmlns="http://www.w3.org/2000/svg"
              className="svg"
            // style={{ position: "absolute", top: 10, left: 15 }}
            >
              <g>
                <circle cx="286" cy="106" r="10" />
                <circle cx="226" cy="106" r="10" />
                <path d="m307 294c4.418-3.313 5.313-9.582 2-14s-9.581-5.313-14-2c-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m397 294c4.418-3.313 5.313-9.582 2-14-3.314-4.418-9.581-5.313-14-2-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m217 294c4.418-3.313 5.313-9.582 2-14s-9.581-5.314-14-2c-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <path d="m127 294c4.418-3.313 5.313-9.582 2-14-3.314-4.418-9.581-5.314-14-2-16.841 12.631-26.5 31.949-26.5 53s9.659 40.369 26.5 53c11.757 8.818 18.5 22.304 18.5 37s-6.743 28.182-18.5 37c-4.418 3.313-5.313 9.582-2 14 3.297 4.396 9.561 5.329 14 2 16.841-12.631 26.5-31.949 26.5-53s-9.659-40.369-26.5-53c-11.757-8.818-18.5-22.304-18.5-37s6.743-28.182 18.5-37z" />
                <circle cx="256" cy="226" r="10" />
                <path d="m472 36h-432c-22.056 0-40 17.944-40 40v150c0 5.523 4.477 10 10 10h201c5.523 0 10-4.477 10-10s-4.477-10-10-10h-131v-40h352v40h-131c-5.522 0-10 4.477-10 10s4.478 10 10 10h201c5.522 0 10-4.477 10-10v-150c0-22.056-17.944-40-40-40zm20 180h-40v-50c0-5.523-4.478-10-10-10h-372c-5.523 0-10 4.477-10 10v50h-40v-140c0-11.028 8.972-20 20-20h432c11.028 0 20 8.972 20 20z" />
              </g>
            </svg>
            <h3 className="title">
              Sie benötigen persönliche
              <br />
              Beratung ?
            </h3>
            <h6>
              Nehmen Sie jetzt ganz unverbindlich
              <br />
              mit uns Kontakt auf, wir beraten Sie gerne.
            </h6>
          </div>
        </div>
        <div className="col-md-6 right-section">
          <form className="contact-form">
            <div className="form-group">
              <select
                value={form.input_1}
                id="inputState"
                className="form-control"
                onChange={e => setForm({ ...form, input_1: e.target.value })}
              >
                <option defaultValue>Anrede*</option>
                <option>Herr</option>
                <option>Frau</option>
              </select>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  placeholder="Name*"
                  type="name"
                  value={form.input_2}
                  className="form-control"
                  onChange={e => setForm({ ...form, input_2: e.target.value })}
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  placeholder="Vorname*"
                  type="surname"
                  value={form.input_3}
                  className="form-control"
                  onChange={e => setForm({ ...form, input_3: e.target.value })}
                />
              </div>
            </div>
            <div className="form-group">
              <input
                type="street"
                placeholder="Strasse*"
                value={form.input_4}
                className="form-control"
                onChange={e => setForm({ ...form, input_4: e.target.value })}
              />
            </div>
            <div className="form-group">
              <input
                type="city"
                value={form.input_5}
                className="form-control"
                onChange={e => setForm({ ...form, input_5: e.target.value })}
                placeholder="Ort*"
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  placeholder="E-Mail*"
                  type="name"
                  value={!location.state ? form.input_9 : form.input_11}
                  className="form-control"
                  onChange={e => {
                    if (!location.state) {
                      setForm({ ...form, input_9: e.target.value });
                      return;
                    }
                    setForm({ ...form, input_11: e.target.value });
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  placeholder="Telefonnummer*"
                  type="tel"
                  value={form.input_7}
                  className="form-control"
                  onChange={e => onPhoneNumberChanged(e)}
                />
              </div>
            </div>
            {location.state && (
              <div className="form-group">
                <select
                  id="inputState"
                  value={form.input_10}
                  className="form-control"
                  onChange={e => setForm({ ...form, input_10: e.target.value })}
                >
                  <option value="" defaultValue>
                    Interesse an Paket:
                  </option>
                  <option value="Basic">Basic</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div
                  className="data-protection"
                  onClick={() =>
                    setForm({
                      ...form,
                      input_8_1:
                        form.input_8_1 === 'Einverstanden'
                          ? ''
                          : 'Einverstanden'
                    })
                  }
                  onKeyDown={() =>
                    setForm({
                      ...form,
                      input_8_1:
                        form.input_8_1 === 'Einverstanden'
                          ? ''
                          : 'Einverstanden'
                    })
                  }
                  role="button"
                  tabIndex={0}
                >
                  <span>
                    Ich bin mit den
                    <a
                      className="daten-text"
                      href="https://kibernetik.ch/datenschutzerklaerung"
                    >
                      {' '}
                      Datenschutzbedingungen
                    </a>{' '}
                    einverstanden.
                  </span>
                  {form.input_8_1 !== '' ? (
                    <i className="far fa-check-square check-icon"></i>
                  ) : (
                    <i className="far fa-square check-icon"></i>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row buttons-bottom pc-bottombtns">
        <div className="col-md-6 col-sm-6 p-md-0 p-sm-0">
          <a href={`${pathToServe}/`}>
            <i className="fas fa-home icons"></i>
          </a>
        </div>
        <div className="col-md-2 col-sm-2 p-md-0 p-sm-0 ">
          <span className="required-field">* Pflichtfelder</span>
        </div>
        <div className="col-md-4 col-sm-4 p-md-0 p-sm-0 flex-end">
          <Link
            className="btn btn-primary btn-default btn-back"
            to={`${pathToServe}/${!location.state ? 'RaumErfassen' : 'EmpfohlenePakete'
              }`}
            role="button"
          >
            Zurück
          </Link>
          <button
            disabled={form.input_8_1 === '' || isLoading}
            className="btn btn-send ml-1"
            type="button"
            onClick={() => onSubmit()}
          >
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center mb-0">
                <Loader size={20} />
              </div>
            ) : (
              'Senden'
            )}
          </button>
        </div>
      </div>

      {formContent && (
        <SimpleModal
          show={showModal}
          content={formContent.confirmation_message || "<div></div>"}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default PersonalContact;
